import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import ThemeLink from '../components/ThemeLink';
import IndustryPartnerItem from '../components/IndustryPartnerItem';
import Layout from '../components/Layout/Layout';

import * as styles from './partners.module.css';

const PartnersPage = (props) => {
  return (
    <Layout>
        <Container>
            <div className={styles.root}>
                <h1 className={styles.header}>Industry Partners</h1>
                <div className={styles.partnerGrid}>
                    <IndustryPartnerItem link="https://doughboypools.com/" altImage='Doughboy' image='./doughboy_partner.png' title="Doughboy" description="The most trusted line of premium above-ground swimming pools for over 75 Years. Doughboy is, and has always been, proud to be made in America."></IndustryPartnerItem>
                    <IndustryPartnerItem link="https://www.lathampool.com/" altImage='Latham' image='./latham_partner.png' title="Latham" description="Latham is the largest designer and manufacturer of inground residential swimming pools in North America. Latham is known throughout the industry as providing the highest-quality products for vinyl pools."></IndustryPartnerItem>
                    <IndustryPartnerItem link="https://www.advancedpoolcoatings.com/" altImage='Advanced Pool Coatings' title="Advanced Pool Coatings" image='./advanced_partner.png' description="Advanced Pool Coatings is a family-owned business with over 25 years of fiberglass resurfacing experience. APC delivers unparalleled quality in swimming pool remodeling, design, construction and care."></IndustryPartnerItem>
                    <IndustryPartnerItem link="https://www.endlesspools.com/" altImage='Endless' image='./endless_partner.png' title="Endless Pools" description="Endless Pools sets the standard in compact pools and aquatic fitness technology."></IndustryPartnerItem>
                </div>
            </div>
        </Container>
    </Layout>
  );
};

export default PartnersPage;
