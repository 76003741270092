import React from 'react';
import * as styles from './IndustryPartnerItem.module.css';

const IndustryPartnerItem = (props) => {
  const {
    link,
    image,
    altImage,
    description,
    title,
  } = props;

  return (
    <div className={styles.root}>
      <a href={link} target="_blank">
        <img alt={altImage} src={image} className={styles.image} />
      </a>
      {title && <div>{title}</div>}
      <p>{description}</p>
    </div>
  );
};

export default IndustryPartnerItem;
